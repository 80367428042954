// DocumentModal.js

import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { Box } from '@mui/system';
import { db } from '../../../../config/firebase';
import { collection, doc, getDocs, query, where, serverTimestamp, updateDoc } from 'firebase/firestore';
import emailjs from '@emailjs/browser';
// import ConsecutiveSnackbars from './ConsecutiveSnackbars';


export const EditModal = ({ open, onClose, selectedRow, onUpdateStatus, selectedData }) => {
  const [selectedStatus, setSelectedStatus] = useState('');
  const [remarks, setRemarks] = useState('');
  const [updateSuccess, setUpdateSuccess] = useState(false);

  const handleSuccessAlert = () => {
    alert('Success: Data edited successfully!');
  };
   
  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleRemarksChange = (event) => {
    setRemarks(event.target.value);
  };

  const serializeEmailBody = () => {
    const userfn = selectedData.firstname.toString();
    const userem = selectedData.email.toString();
    console.log(remarks)
    return {
      user_firstname: userfn,
      user_email: userem,
      request_name: selectedRow.firstname.toString() + " " + selectedRow.lastname.toString(),
      request_requestType: selectedRow.formType.toString(),
      request_requestDate: selectedRow.requestDateTime.toDate().toString(),
      request_Remarks: remarks,
      request_requestStatus: selectedStatus,
    };
  };

  const handleSubmit = async () => {
    console.log('uda:',selectedRow.requestDateTime.toDate())

    try {
      const usersCollectionRef = collection(db, 'users');
      const userQuerySnapshot = await getDocs(query(usersCollectionRef, where('userId', '==', selectedRow.userId)));

      if(userQuerySnapshot.size > 0){
        const userDocRef = userQuerySnapshot.docs[0].ref;
        const requestsCollectionRef = collection(userDocRef, 'requests');
  
     
  
        const documentData = {
          requestedDateTime: serverTimestamp(),
          availability: selectedStatus,
          dateClaimed: selectedStatus === 'Claimed' ? serverTimestamp() : null,
        };
        
        console.log(documentData);
        // Assuming data.id is the ID of the document you want to update
        const documentRef = doc(requestsCollectionRef, selectedRow.id);
  
        await updateDoc(documentRef, documentData);
  
        console.log('Document successfully updated:', documentRef);

        // Show success alert
         handleSuccessAlert();  
        
        // Close the modal after successfully updating the document
        onClose();
      }
    } catch (err) {
      console.error(err);
    }
    // const requestDocRef = doc(db, 'users', selectedRow.id, 'requests', selectedRow.id);
    // console.log('Request document reference:', requestDocRef);
    // // ... rest of the code
    // onUpdateStatus(selectedRow.id, selectedStatus, remarks);
    // onClose();
  };
    
 //send email to corresponding user
 const sendEmailToUser = () => {
  const serializedEmailBody = serializeEmailBody();
  console.log(serializedEmailBody)
  emailjs.send(
    process.env.REACT_APP_EMAIL_JS_SERVICE_ID,
    'template_ccch0if',
    serializedEmailBody,
    process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY
  )
  .then((result) => {
    console.log('Email sent successfully:', result);
           
           // Reload the page after successfully updating the document
           window.location.reload();
  })
  .catch((error) => {
    console.log(error.text);
  });
}

  return (
    <Box>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle sx={{ textAlign: 'center' }}>Document Details</DialogTitle>
        <DialogContent sx={{ textAlign: 'center' }}>
          <p style={{ marginBottom: '10px' }}>
            <strong>Selected Name:</strong> {selectedRow ? `${selectedRow.firstname} ${selectedRow.lastname}` : ''}
          </p>
          <FormControl fullWidth sx={{ mb: '20px' }}>
            <InputLabel>Availability Status: </InputLabel>
            <Select value={selectedStatus} onChange={handleStatusChange}>
              <MenuItem value="Claimed" style={{ color: 'green' }}>Claimed</MenuItem>
              <MenuItem value="Unclaimed" style={{ color: 'red' }}>Unclaimed</MenuItem>
            </Select>
          </FormControl>
          <Button onClick={handleSubmit} color="primary" variant='contained' fullWidth sx={{ mb: '20px' }}>
            Submit
          </Button>
          <Button onClick={onClose} color="primary" variant='outlined' fullWidth>
            Cancel
          </Button>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
