import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router';
import { auth } from '../config/firebase';


export const Protected = () => {
    const [user, setUser] = useState(null);

  useEffect(() => {
    // Listen for changes in the user's authentication state
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      setUser(authUser);
    });

    // Cleanup the subscription when the component unmounts
    return () => unsubscribe();
  }, []);

    const token = localStorage.getItem('cache');
    
    return(
        token ? <Outlet /> : <Navigate to="/login"/>
    )

}
