import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Stack,
  CircularProgress,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import logo from '../../images/anhslogo.png';
import background from '../../images/office.jpg';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { auth } from "../../config/firebase";
import {signInWithEmailAndPassword} from 'firebase/auth'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { IconButton } from "@mui/material";
import useTheme from '@mui/system/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useCache } from '../../components/cache-provider';
import { useNavigate } from "react-router-dom";

export const LoginPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { addToCache } = useCache();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(false);
  const [pwdMsg, setPwdMsg] = useState('');
  const [emMsg, setEmMsg] = useState('');
  const [showPassword, setShowPassword] = useState(false); 
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarColor, setSnackbarColor] = useState('');

  const timer = useRef();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  //remember me 
  const handleRememberChange = (checked) => {
    //to check if its functional --
    console.log("Remember Me Checked:", checked);
    setRemember(checked);
    if (!checked) {
      localStorage.removeItem('rememberedEmail');
    }
  };

  //signins user
  const handleSignIn = async () => {
    if(handleValidation()){
      try {
        setLoading(true);
        const result = await signInWithEmailAndPassword(auth, email, password);
        const data = result.user;
        console.log(data);
        if (remember) {
          addToCache({ accessToken: data.accessToken, uid: data.uid }, remember);
          localStorage.setItem('rememberedEmail', email);
        } else {
          localStorage.removeItem('rememberedEmail');
          localStorage.setItem('cache', JSON.stringify({ accessToken: data.accessToken, uid: data.uid }));
        }

        navigate('/dashboard');
        setLoading(false);
        setSnackbarMessage('Login successful!');
        setSnackbarColor('success');
        setOpenSnackbar(true);
      } catch (err) {
        console.error(err);
        setLoading(false);

        let errorMessage = 'An error occurred. Please try again.';
        let errorColor = 'error';

        switch (err.code) {
          case 'auth/wrong-password':
            errorMessage = 'Incorrect Password. Please check your password and try again.';
            break;
          case 'auth/network-request-failed':
            errorMessage = 'Unable to connect. Please check your internet connection.';
            break;
          case 'auth/user-not-found':
            errorMessage = 'User not found. Please check the email you entered, and try again';
            break;
          case 'auth/invalid-email':
            errorMessage = 'Invalid email format. Please provide a valid email.';
            break;
          case 'auth/too-many-requests':
            errorMessage = 'Too many unsuccessful sign-in attempts. For security reasons, your account has been temporarily locked. Please try again later or reset your password.';
            break;
          case 'auth/invalid-login-credentials':
            errorMessage = 'Email and password do not match. Please try again.';
            break;
          // Add more cases for specific error codes as needed
        }

        setSnackbarMessage(errorMessage);
        setSnackbarColor(errorColor);
        setOpenSnackbar(true);
       }
      finally{setLoading(false);}
    }
  }

  const [validationError, setValidationError] = useState({
      email: false,
      password: false,
  });

  const handleValidation = () => {
  const updatedValidationError = {
      email: !email.trim(),
      password: !password.trim(),
  };
  setPwdMsg('Password is Required');
  setEmMsg("Email is Required. If you haven't an account yet, kindly ask super-admin to create your account.");
  setValidationError(updatedValidationError);
  // Return true if there are no validation errors
  return !Object.values(updatedValidationError).some((error) => error);
  };

  return (
    <Grid container component="main" sx={{
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundImage: `url(${background})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      overflow: "hidden",
    }} >      
      <Grid />
      <Grid item xs={12} sm={8} md={6} elevation={6} square container justifyContent="center" alignItems="center" >
        <Box bgcolor={'#FFFBF5'} borderRadius={2} sx={{ width: '80%', height: '500', padding: 3, boxShadow: 4, }}>
          <Stack spacing={2}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <img src={logo} style={{ height: "120px" }} alt="Logo" />
              <div>
                <h1 style={{ color: '#528AD7' }}>Archive Hub Admin</h1>
                <h2 style={{ color: '#00000' }}>Admin Login Portal</h2>
              </div>
            </Stack>
            {snackbarMessage && (
              <Alert sx={{ color: 'black', marginBottom: 2 }} severity={snackbarColor}>
                {snackbarMessage}
              </Alert>
            )}
            <Box>
              <TextField
                margin="normal"
                autoFocus
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                error={validationError.email}
                helperText={validationError.email ? emMsg : ''}
                onFocus={() => setValidationError({ ...validationError, email: false })}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                id="password"
                type={showPassword ? 'text' : 'password'}
                onChange={(e) => setPassword(e.target.value)}
                error={validationError.password}
                helperText={validationError.password ? pwdMsg : ''}
                onFocus={() => setValidationError({ ...validationError, password: false })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon /> }
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Stack direction="row" justifyContent="space-between" sx={{mb: 2, mt: 2}}>
                <FormControlLabel
                  sx={{
                    textAlign: 'left',
                  }}
                  control={<Checkbox   onChange={(e) => handleRememberChange(e.target.checked)}
                  checked={remember} />}
                  label="Remember Me?"
                />
                <Button   href="forgot" color="primary"
                sx={{ 
                  textAlign: 'right' ,
                  textTransform: 'none',
              
                  }}>
                  Forgot your Password?
                </Button>
                </Stack>

              <Stack direction="row" justifyContent="center">
              <Button
                onClick={handleSignIn}
                variant="contained"
                fullWidth
                size="large"
                sx={{ color: 'white', backgroundColor: 'main', position: 'relative' }}
                disabled={loading} // Disable the button when loading
                >
                {loading && <CircularProgress size={24} sx={{ color: 'white', position: 'absolute', top: '50%', left: '50%', marginTop: '-12px', marginLeft: '-12px' }} />} 
                {/* Show CircularProgress when loading */}
                    Login
                </Button>
              </Stack>
            </Box>
          </Stack>
        </Box> 
        <Typography sx={{ color: '#CFBEBE', position: 'absolute', bottom: 0, textAlign: 'center', width: '100%', mb: 2 }}>
          Version 2
        </Typography>
      </Grid>
    </Grid>
  );
};

