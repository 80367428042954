import * as React from 'react';
import {
  Box, Paper,
} from '@mui/material';
import { AppDrawer } from '../components/app-drawer';
import { DashboardModule } from './componets/module';





export const DashboardPage = () => {



  return (
    <Box sx={{ display: 'flex', }}>
      <AppDrawer/>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: '100%',
          overflow: 'auto',
          position: 'relative',
          // bgcolor:'#f5f5f5'         
        }}
      >
          <Box  
          sx={{
            pt:'10px',
          }} >
              <DashboardModule/>
          </Box>
        </Box>
      </Box>
    );
  };
