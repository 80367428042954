import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Collapse from '@mui/material/Collapse';
import { keyframes } from '@emotion/react';

//firebase
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { auth, db } from "../../../config/firebase";

export const AddStudentModal = () =>  {
  const [open, setOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [error, setError] = useState('');
  
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    lrn: '',

  });

   //generates character
   const generateRandomString = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    
    const getRandomChar = (pool) => pool[Math.floor(Math.random() * pool.length)];
  
    let randomString = '';
    for (let i = 0; i < 6; i++) {
      randomString += getRandomChar(characters);
    }
  
    return randomString;
  };

  const handleInputChange = (e) => {
    
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

  // Clear the error when the user starts typing
  if (error && formData[e.target.name] !== '') {
    setError('');
  }
  };

  const handleOpen = () => {
    setOpen(true);
    setError(''); // Reset error when opening the main modal
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmationOpen = () => {
    const { firstName, lastName, lrn,  } = formData;
  
  // Validate the fields
  if (firstName === '' || lastName === '' || lrn === '' ) {
    setError('Please fill in all required fields.'); // Set error if required fields are not filled
  } else {
    let firstNameError = '';
    let lastNameError = '';
    let lrnError = '';

    // Validate First Name
    if (!/^[a-zA-Z ]+$/.test(firstName)) {
      firstNameError = 'First Name should contain only letters.';
    }

    // Validate Last Name
    if (!/^[a-zA-Z ]+$/.test(lastName)) {
      lastNameError = 'Last Name should contain only letters.';
    }

    // Validate LRN
    if (!/^\d{12}$/.test(lrn)) {
      lrnError = 'LRN should be a 12-digit number.';
    }

    // Set errors if any
    if (firstNameError || lastNameError || lrnError) {
      setError(`${firstNameError} ${lastNameError} ${lrnError}`);
    } else {
      setError('');
      setConfirmationOpen(true);
    }
  }
};

  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
    setFormData({
      firstName: '',
      lastName: '',
      lrn: '',
    });
    handleClose();
  };
  // go back to first modal
  const handleEdit = () => {
    handleConfirmationClose();
    handleOpen();
  };
  // to submit the requested form
  const handleSubmit = async () => {    
    try {
      const studentCollectionRef = collection(db, 'students');
      
      // Assuming formData is the state holding your form data
      const { firstName, lastName, lrn, } = formData;

      const studentInforamtion = await addDoc(studentCollectionRef, {
        firstname: firstName,
        lastname: lastName,
        dateAdded: serverTimestamp(),
        id: lrn,
      });

      console.log('Request successfully added:', studentInforamtion);
        // Show an alert after successful submission
    alert('Form submitted successfully!');
    } catch (err) {
      console.error('Error adding request:', err);
    }
  };
  // to close both modal
  const handleCancel = () => {
    setFormData({
      firstName: '',
      lastName: '',
      lrn: '',
    });
    handleClose();
    handleConfirmationClose();
  };

  const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
`;

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleOpen}>
      Add Student
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Grow in={open}>
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400, // Adjust the width as needed
              backgroundColor: '#fff',
              padding: 20,
              borderRadius: 8,
              outline: 'none',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '-10px' }}>
              <IconButton color="black" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>

            <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Add Student Form</h2>
            <Collapse in={!!error} timeout={500}>
              <div style={{ marginTop: '10px', animation: `${bounce} 1s ease` }}>
                <Alert severity="error">
                  <AlertTitle>Oops!</AlertTitle>
                  {error}
                </Alert>
              </div>
            </Collapse>
            <TextField
              required
              label="First Name"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              variant="outlined"
              helperText={error && formData.firstName === '' ? 'Enter your first name' : ''}
              error={error && formData.firstName === ''}
            />
            <TextField
              required
              label="Last Name"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              variant="outlined"
              helperText={error && formData.lastName === '' ?  'Enter your last name' : ''}
              error={error && formData.lastName === ''}
            />
           <TextField
              required
              label="LRN (Learner's Reference Number)"
              name="lrn"
              value={formData.lrn}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              variant="outlined"
              helperText={
                (error && formData.lrn === '') ? 'Enter your LRN' :
                (error && !/^\d{12}$/.test(formData.lrn)) ? 'LRN should be a 12-digit number' :
                ''
              }
              error={(error && formData.lrn === '') || (error && !/^\d{12}$/.test(formData.lrn))}
              inputProps={{
                inputMode: 'numeric', // Set input mode to numeric for mobile users
                pattern: '[0-9]*',    // Allow only numeric input
              }}
            />
    
            <Button variant="contained" color="primary" onClick={handleConfirmationOpen} style={{ marginTop: 20, width:'100%' }}>
              Submit
            </Button>
          </div>
        </Grow>
      </Modal>

      <Modal open={confirmationOpen} onClose={handleConfirmationClose}>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            backgroundColor: '#fff',
            padding: 20,
            borderRadius: 8,
            outline: 'none',
          }}
        >
          <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Cofirm your Request</h2>
           <div style={{ marginBottom: 15, background: formData.firstName ? '#e6f7ff' : 'transparent' ,fontSize: '19px',}}>
            <strong>First Name:</strong> {formData.firstName}
          </div>
          <div style={{ marginBottom: 15, background: formData.lastName ? '#e6f7ff' : 'transparent',fontSize: '19px', }}>
            <strong>Last Name:</strong> {formData.lastName}
          </div>
          <div style={{ marginBottom: 15, background: formData.lrn ? '#e6f7ff' : 'transparent',fontSize: '19px', }}>
            <strong>LRN:</strong> {formData.lrn}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Button variant="contained" color="secondary" onClick={handleEdit} style={{ marginBottom: 10 }}>
              Edit
            </Button>
            <Button variant="contained" color="primary" onClick={() => { handleSubmit(); handleConfirmationClose(); }}  style={{ marginBottom: 10 }}>
              Confirm
            </Button>
            <Button variant="outlined" color="primary" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

