import * as React from 'react';
import {
  Box, Paper, Typography,
} from '@mui/material';
import { AppDrawer } from '../components/app-drawer';
import { DocumentsTable } from './components/document-table';
// import { DocumentsModule } from './components/docu-module';




export const AdminDocuments = () => {



  return (
    <Box sx={{ display: 'flex', }}>
      <AppDrawer/>
      <Box     
      paddingTop={'60px'}
        component="main"
        sx={{
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
          position: 'relative',
          bgcolor:'#FFFFF0',

        }}
      >
          <Box  
          sx={{
            mt: '30px',
            pt:'10px',
            pl: '10px',
            pr: '10px',
            bgcolor: '#FFFFF0',
            m: '20px',
            pb: '20px',
            maxHeight:'460vh',
            borderRadius:'10px',
            
          }} >
      
            <DocumentsTable/>
           {/* <DocumentsTable/> */}
          </Box>
        </Box>
      </Box>
    );
  };