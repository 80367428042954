import * as React from 'react';
import { AppDrawer } from '../components/app-drawer';
import { ArchiveTable } from './components/archive-table';
import { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Paper,
  Stack,
  Checkbox,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  InputAdornment,
  IconButton,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import ReplayOutlined from '@mui/icons-material/ReplayOutlined'
import { AddOutlined } from '@mui/icons-material';

//firebase
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { AddStudentModal } from './components/add-student-modal';
import { ApprovedPage } from './approved-data/page';
import { ApprovedTable } from './approved-data/components/table';



export const AdminArchive = () => {

  const [reload, setReload] = useState(false); // State to trigger data reload
  const [searchQuery, setSearchQuery] = useState(''); // Add this line
  const [studentsData, setStudentsData] = useState([]);

  const handleReload = async () => {
    console.log('reloading data');
    try {
      const studentCollectionRef = collection(db, 'students');
      const querySnapshot = await getDocs(studentCollectionRef);
      const resultData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setStudentsData(resultData);
    } catch (error) {
      console.error('Error reloading data:', error);
    } finally {
      setReload(!reload);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    console.log('searching data:', searchQuery);
  };

  useEffect(() => {
    handleReload(); // Initial load of data
  }, []); // Empty dependency array ensures this runs once on component mount


  return (
    <Box sx={{ display: 'flex', }}>
      <AppDrawer/>
      <Box
        component="main"
         flexDirection="column" 
        sx={{
          flexGrow: 1,
          height: '50%',
          overflow: 'auto',
          position: 'relative',
          // bgcolor:'#f5f5f5'   
              
        }}
      >
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
          position: 'relative',
        }}
      >
        <Container maxWidth="100%" sx={{ mt:10,   }}>
          <Stack direction="column" spacing={1}>
          <Paper elevation={3} sx={{ overflow: 'auto'}}>
               <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                padding={2}
                sx={{ position: 'sticky', top: 0, backgroundColor: '#fff', zIndex: 1000 }}
              >
                <Box>
                  <h2>Students Archive</h2>
                </Box> 
                <Stack direction="row" spacing={1} alignItems="center" sx={{ paddingTop: 1 }}>
                <form
                   onSubmit={handleSearch}
                    sx={{
                      '& > :not(style)': { height: '30px', width: '200px', paddingTop: 7 },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      placeholder=" Name or LRN"
                      id="outlined-basic"
                      label="Search"
                      variant="outlined"
                      elevation={3}
                      size='small'
                      sx={{ width: '250px', color: 'rgba(0, 0, 0, 0.7)' }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <IconButton>
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </form>
                    {/* <TableModal /> */}
                    <Button variant='contained' startIcon={<ReplayOutlined />} onClick={handleReload}>Reload</Button>
                    <AddStudentModal/>
                </Stack>
              </Stack>
              <ArchiveTable reload={reload} searchQuery={searchQuery} data={studentsData}/>
            </Paper> 
            {/* //main table */}
             <ApprovedPage />
          </Stack>
        </Container>
    </Box>               
  </Box>
 </Box> 
);
};