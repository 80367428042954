import * as React from 'react';
import { Box, Container, Paper , Grid, Typography, Stack } from '@mui/material';
import NumberOfStudents from '../componets/num-of-student';
import RegisteredUsers from '../componets/num-of-registerd-user';
import NumberOfTeachers from '../componets/total-of-teachers';
import { RequestLineChart } from '../componets/line-chart';
import { UserPieChart } from '../componets/pie-charts';
import { Card } from '@mui/material';



export const DashboardModule = () => {
  return (
    <Box sx={{ display: 'flex', overflowX: 'auto' }}>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: '100vh',
        }}
      >
        <Stack 
        sx={{
          pt:'6%', 
          width: '100%',
          paddingLeft:'20px',
          paddingRight:'20px',
          pb:'30px'
          }}>
          <Stack container spacing={3}>
            <Stack direction="row" spacing={2}>
              <Card elevation="2" sx={{width:'100%' , bgcolor:'white' , p:2, }}>
                <RegisteredUsers />
              </Card>

              <Card elevation="2" sx={{width:'100%' , bgcolor:'white' , p:2,}}>
                <NumberOfStudents />        
              </Card>

              <Card elevation="2" sx={{width:'100%' , bgcolor:'white' , p:2,}}>
                <NumberOfTeachers />
              </Card>
            </Stack>

            <Stack direction="row" spacing={2}>
              <Card elevation="2" sx={{width:'100%' , bgcolor:'white' , p:2,}}>
                 <RequestLineChart />
              </Card>

              {/* <Card  elevation="2" sx={{width:'100%', bgcolor:'white', p:2,}}>
                 <UserPieChart />
              </Card> */}
            </Stack>

          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};