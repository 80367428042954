import React from 'react';
import {
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import LOGO from '../../images/anhslogo.png';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from '../../config/firebase';
import { sendPasswordResetEmail } from "firebase/auth";
import out from '../../images/anhsbackground.png';


export const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const navigate = useNavigate();

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);

    if (showSuccessAlert) {
      navigate("/login");
    }
  };

  const handleOpenSuccessModal = () => {
    setOpenSuccessModal(true);
  };

  const handleCloseSuccessModal = () => {
    setOpenSuccessModal(false);
    navigate("/login");
  };

  const validateEmail = (email) => {
    // Simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = e.target.email.value;

    if (!validateEmail(email)) {
      setShowErrorAlert(true);
      setErrorMessage('Invalid email address');
      return;
    }
    try {
      setLoading(true);
      await sendPasswordResetEmail(auth, email);
      setShowSuccessAlert(true);
      setShowErrorAlert(false);
      // setErrorMessage('');
      handleOpenSuccessModal();
    } catch (err) {
      setLoading(false);
      setShowSuccessAlert(false);
      setShowErrorAlert(true);
      setErrorMessage('Failed resetting password. Please try again.');
      // handleOpenModal();
    }
  };


  const handleEmailChange = () => {
    // Clear error state when the user starts typing
    setShowErrorAlert(false);
    setErrorMessage('');
  };
  
  return (
    <Grid container component="main" sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative", // Make the container relative
        overflow: "hidden",
      }}>
          {/* Background Image */}
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundImage: `url(${out})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        filter: "blur(5px)", // Add the blur filter
        zIndex: -1, // Move the background behind other content
      }}
    />
      <Grid />
      <Grid item xs={12} sm={8} md={6} elevation={6} square container justifyContent="center" alignItems="center" >
        <Box bgcolor={'#FFFBF5'} sx={{ width: '80%', padding: 3, boxShadow: 4 }}>
          <Stack spacing={2}>

            <Stack direction="row" alignItems="center" spacing={1}>
              <img src={LOGO} style={{ height: "120px" }} alt="Logo" />
              <div>
                <h1 style={{ color: '#528AD7' }}>Archive Hub Admin</h1>
                <h2 style={{ color: '#00000' }}>Recovery Password</h2>
              </div>
            </Stack>
            {showErrorAlert && (
              <Alert severity="error" sx={{ marginTop: 4 , marginLeft: 10, marginRight: 10, marginBottom: 4 }}>
                {errorMessage}
              </Alert>
            )}
            {showSuccessAlert && (
              <Alert severity="success" sx={{ marginTop: 2 , marginLeft: 2, marginRight: 2, marginBottom: 2 }}>
              </Alert>
            )}

            <form onSubmit={handleSubmit}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={handleEmailChange}
              />
              <Button type="submit" variant="contained" sx={{ width: '100%', color: 'white', backgroundColor: '#528AD7' }} >Confirm </Button>
            </form>

            <Stack direction="row" alignItems="center" justifyContent="center" spacing={4}>
              <Typography variant="body1">
                Click here to return to{' '}
                <Button href="login" variant="text" style={{ textTransform: 'none' }}>Login page</Button>
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Grid>

      {/* Modal for success message */}
      <Dialog open={openSuccessModal} onClose={handleCloseSuccessModal}>
            <Alert severity="success" sx={{ marginTop: 2, marginLeft: 2, marginRight: 2, padding: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {/* Customized message */}
            <h3>Password reset link sent to your email</h3>
            <p>Check your email for further instructions.</p>
            </Alert>
        <DialogActions sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button variant='outlined' onClick={handleCloseSuccessModal} color="primary" >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal for displaying messages */}
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>{showSuccessAlert ? 'Success' : 'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {showSuccessAlert ? (
              <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
                <CircularProgress color="primary" />
                <Typography variant="body2">Checking your email...</Typography>
              </Stack>
            ) : (
              <Alert severity="error">
                {errorMessage}
              </Alert>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
  <Stack direction="row" justifyContent="center" alignItems="flex-end">
    <Button variant='text' onClick={handleCloseModal} color="primary">
      Close
    </Button>
  </Stack>
</DialogActions>
      </Dialog>
    </Grid>
  );
};
