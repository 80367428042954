import { Box, Button, Typography } from '@mui/material';
import React from 'react';

export const PageNotFound = () => {
  return (
    <Box
    sx={{
      position:'flex',
      marginTop: '2rem',
      textAlign: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundSize: '100%',
      backgroundPosition: 'top',
        
      
      '@media (min-width:600px)': {
          position:'flex',
          marginTop: '2rem',
          textAlign: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundSize: '100%',
          backgroundPosition: 'top',
       
           
      },
    }}
    >
      <Typography sx={{ fontSize: 150, fontWeight: 900, marginBottom: 0, background: 'linear-gradient(45deg, #19C8FF 34%, #0400DC 90%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>
  Oops!
</Typography>

      <Typography sx={{ fontSize: 20, fontWeight: 'bold',   marginBottom: 2}}>
        404 - PAGE NOT FOUND
      </Typography>
      <Typography sx={{ fontSize: 20, fontWeight: 400,   marginBottom: 2}}>
        Sorry, the page you are looking for does not exist or is temporary unavailable.
      </Typography>
      
      <Button variant='contained' href='/dashboard'
       sx={{width: '20%', fontSize: '12px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)', }}>Back to Home</Button>
    </Box>
  );
};