import * as React from 'react';
import { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Paper,
  Stack,
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
  Button,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { UserAccountsTable } from './components/component';
import { db } from '../../../../config/firebase';
import { collection, getDocs, query } from 'firebase/firestore';
import { ReplayOutlined } from '@mui/icons-material';

export const UserAccountsModule = () => {
  const [userData, setUserData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [filteredUserData, setFilteredUserData] = useState([]);
  
  const fetchAdminData = async () => {
    const accountsCollection = collection(db, 'users');

    try {
      const querySnapshot = await getDocs(query(accountsCollection));

      const userData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      console.log('User Data:', userData);
      setUserData(userData);
      setFilteredUserData(userData);
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setLoading(false); // Set loading to false after fetching data
    }
  };
  useEffect(() => {
    

    fetchAdminData();
  }, []);

  const handleReloadData = () =>{
    fetchAdminData();
  }

  return (
    <Stack sx={{ bgcolor: '#FFFFF0' }}>
      <Box component="main">
        <Container maxWidth="100%">
          <Stack direction="column" spacing={1}>
            <Paper elevation={3} sx={{ overflow: 'auto' }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                padding={2}
              >
                <Box>
                  <h2> User Accounts</h2>
                </Box>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  sx={{ paddingTop: 1 }}
                >
                 
                  <Button variant='contained' startIcon={<ReplayOutlined />} onClick={handleReloadData}>Reload</Button>
                </Stack>
              </Stack>
              {loading ? (
                <CircularProgress />
              ) : (
                <UserAccountsTable data={filteredUserData} />
              )}
            </Paper>
          </Stack>
        </Container>
      </Box>
    </Stack>
  );
};
