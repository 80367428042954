import * as React from 'react';
import {
  Box,
  Paper,
} from '@mui/material';
import { AppDrawer } from '../components/app-drawer';
import { UserAccountsModule } from './components/user-accounts/user-accounts-module';
import { AdminAccountsModule } from './components/admin-accounts/admin-accounts-module';


export const AccountPage = () => {



  return (
    <Box sx={{ display: 'flex', }}>
      <AppDrawer/>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
          position: 'relative',
          bgcolor:'#FFFFF0'
        }}
      >
          <Box  sx={{
              pt:'100px',
            }}  >
              <UserAccountsModule/>
              <AdminAccountsModule/>
          </Box>
        </Box>
      </Box>
    );
  };
  

  