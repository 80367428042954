import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Box, Button, Grid, CircularProgress, IconButton } from '@mui/material';


const columns = [
  { id: 'firstname', label: 'First Name', minWidth: 100 },
  { id: 'lastname', label: 'Last Name', minWidth: 100 },
  { id: 'email', label: 'Email Address', minWidth: 100 },
  { id: 'address', label: 'Address', minWidth: 100 },
  { id: 'role', label: 'Role', minWidth: 100 },
  { id: 'contactNumber', label: 'Contact Number', minWidth: 100 },
  { id: 'accountStatus', label: 'Account Status', minWidth: 100 },
  { id: 'accountRegisteredDateTime', label: 'Date Registered', minWidth: 100 },
];

export const AdminAccountsTable = ({ data }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [loading, setLoading] = React.useState(data.length === 0); // Set loading to true initially if there's no data

  React.useEffect(() => {
    setLoading(data.length === 0); // Update loading based on the length of the data array
  }, [data]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  <CircularProgress size={50} sx={{ color: '#3A78CD' }} />
                </TableCell>
              </TableRow>
            ) : (
              data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === 'number'
                          ? column.format(value)
                          : column.id === 'accountRegisteredDateTime' &&
                            value &&
                            value.toDate
                            ? value.toDate().toLocaleString() // Format date if 'toDate' method is available
                            : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};