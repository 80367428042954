import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from 'reactstrap';

import { auth, db } from '../../../config/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';

function preventDefault(event) {
  event.preventDefault();
}

const RegisteredUsers = () => {

  const [userCount, setUserCount] = useState(0);
  //get number of users
  const getNumberOfUsers = async () => {
    const numberOfUsersRef = collection(db, 'users');

    try {
      const querySnapshot = await getDocs(numberOfUsersRef);

      // Update userCount with the number of documents in the query result
      setUserCount(querySnapshot.size);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    // Call the function to get the initial user count
    getNumberOfUsers();
  }, []);

  const getCurrentDate = () => {
    const currentDate = new Date();
    return currentDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const [currentDate, setCurrentDate] = useState(getCurrentDate);


  return (
    <Card className="shadow mb-4" style={{ height: '230px' }}>
      <CardHeader className="border-0">
        <Row className="align-items-center">
          <Col xs="8">
            <h3 className="mb-0" style={{ fontSize: '22px', padding:'5px', color:'#25203A'  }}>Registered Users</h3>
          </Col>
          <Col className="text-right" xs="4">
            
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <div className="mb-3">
        <CardTitle tag="h4" className="text-uppercase text-muted mb-0" style={{ fontSize: '16px' }}>
        Total Number of Registered Users
        </CardTitle>

          <span className="h2 font-weight-bold mb-0">{userCount.toString()}</span>
        </div>
        <p className="" style={{ color:'#25203A' }}>
          Last updated on {currentDate.toString()}
        </p>
      </CardBody>
    </Card>
  );
};

export default RegisteredUsers;