import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Collapse from '@mui/material/Collapse';
import { keyframes } from '@emotion/react';

//firebase
import { addDoc, collection, serverTimestamp, doc, query, where, getDocs } from "firebase/firestore";
import { auth, db } from "../../../config/firebase";

export const AddDocModal = ({data}) =>  {
  const [open, setOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [error, setError] = useState('');
  
  const [formData, setFormData] = useState({
    lrn: '',
    documentType: '',
    Availability: ''
  });

   //generates character
   const generateRandomString = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    
    const getRandomChar = (pool) => pool[Math.floor(Math.random() * pool.length)];
  
    let randomString = '';
    for (let i = 0; i < 6; i++) {
      randomString += getRandomChar(characters);
    }
  
    return randomString;
  };

  const handleInputChange = (e) => {
    
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    

  // Clear the error when the user starts typing
  if (error && formData[e.target.name] !== '') {
    setError('');
  }
  };

  const handleOpen = () => {
    setOpen(true);
    setError(''); // Reset error when opening the main modal

    setFormData((prevFormData) => ({
      ...prevFormData,
      lrn: data.id,
    }));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmationOpen = () => {
    const { lrn, documentType } = formData;
  
  // Validate the fields
  if (  lrn === '' || documentType === '') {
    setError('Please fill in all required fields.'); // Set error if required fields are not filled
  } else {
 
    let lrnError = '';



    // Validate LRN
    if (!/^\d{12}$/.test(lrn)) {
      lrnError = 'LRN should be a 12-digit number.';
    }

    // Set errors if any
    if ( lrnError) {
      setError(` ${lrnError}`);
    } else {
      setError('');
      setConfirmationOpen(true);
    }
  }
};

  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
    setFormData({
      lrn: '',
      documentType: '',
    });
    handleClose();
  };
  // go back to first modal
  const handleEdit = () => {
    handleConfirmationClose();
    handleOpen();
  };
  // to submit the requested form
  const handleSubmit = async () => {
    console.log('submit data', data.id);
    
    try {
      const studentCollectionRef = collection(db, 'students');

      const userQuery = query(studentCollectionRef, where('id', '==', data.id));
      console.log(userQuery)
      const userQuerySnapshot = await getDocs(userQuery);
      console.log(userQuerySnapshot)
      if(userQuerySnapshot.size > 0){
        console.log('found');
        const userDocRef = userQuerySnapshot.docs[0].ref;

        const documentsCollectionRef = collection(userDocRef, 'documents');

        // Assuming formData is the state holding your form data
        const { documentType, Availability } = formData;

        const requestInformation = await addDoc(documentsCollectionRef, {
          updatedDateTime: serverTimestamp(),
          documentType: documentType,
          availability: Availability // Corrected field name here
        });

        console.log('Request successfully added:', requestInformation);
      }
   
    
    

      
        // Show an alert after successful submission
    alert('Form submitted successfully!');
    } catch (err) {
      console.error('Error adding request:', err);
    }
  };
  // to close both modal
  const handleCancel = () => {
    setFormData({
      lrn: '',
      documentType: '',
    });
    handleClose();
    handleConfirmationClose();
  };

  const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
`;

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Add Document
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Grow in={open}>
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400, // Adjust the width as needed
              backgroundColor: '#fff',
              padding: 20,
              borderRadius: 8,
              outline: 'none',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '-10px' }}>
              <IconButton color="black" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>

            <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Add Document Form</h2>
            <Collapse in={!!error} timeout={500}>
              <div style={{ marginTop: '10px', animation: `${bounce} 1s ease` }}>
                <Alert severity="error">
                  <AlertTitle>Oops!</AlertTitle>
                  {error}
                </Alert>
              </div>
            </Collapse>
         
           <TextField
              required
              label="LRN (Learner's Reference Number)"
              name="lrn"
              disabled
              value={formData.lrn}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              variant="outlined"
              error={(error && formData.lrn === '') || (error && !/^\d{12}$/.test(formData.lrn))}
              inputProps={{
                inputMode: 'numeric', // Set input mode to numeric for mobile users
                pattern: '[0-9]*',    // Allow only numeric input
              }}
            />
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel htmlFor="documentType">Document Type</InputLabel>
              <Select
                required
                value={formData.documentType}
                onChange={handleInputChange}
                inputProps={{
                  name: 'documentType',
                  id: 'documentType',
                }}
                label="Document Type"
                helperText={formData.documentType ? '' : 'Select the document type'}
                error={error && formData.documentType === ''}  
              >
                <MenuItem value="Form 137">Form 137</MenuItem>
                <MenuItem value="Good Moral">Good Moral</MenuItem>
                <MenuItem value="Diploma">Diploma</MenuItem>
                <MenuItem value="Report Card">Report Card</MenuItem>
                <MenuItem value="Certificate Of Enrollment">Certificate of Enrollment</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel htmlFor="Availability">Availability</InputLabel>
              <Select
                required
                value={formData.Availability}
                onChange={handleInputChange}
                inputProps={{
                  name: 'Availability',
                  id: 'Availability',
                }}
                label="Availability"
                helperText={formData.Availability ? '' : 'Select the document type'}
                error={error && formData.Availability === ''}  
              >
                <MenuItem value="available">Available</MenuItem>
                <MenuItem value="unavailable">Unavailable</MenuItem>
                <MenuItem value="claimed">Claimed</MenuItem>
              </Select>
            </FormControl>
            <Button variant="contained" color="primary" onClick={handleConfirmationOpen} style={{ marginTop: 20, width:'100%' }}>
              Save
            </Button>
          </div>
        </Grow>
      </Modal>

      <Modal open={confirmationOpen} onClose={handleConfirmationClose}>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            backgroundColor: '#fff',
            padding: 20,
            borderRadius: 8,
            outline: 'none',
          }}
        >
          <div style={{ marginBottom: 15, background: formData.lrn ? '#e6f7ff' : 'transparent',fontSize: '19px', }}>
            <strong>LRN:</strong> {formData.lrn}
          </div>
          <div style={{ marginBottom: 15, background: formData.documentType ? '#e6f7ff' : 'transparent',fontSize: '19px', }}>
            <strong>Document Type:</strong> {formData.documentType}
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Button variant="contained" color="secondary" onClick={handleEdit} style={{ marginBottom: 10 }}>
              Edit
            </Button>
            <Button variant="contained" color="primary" onClick={() => { handleSubmit(); handleConfirmationClose(); }}  style={{ marginBottom: 10 }}>
              Confirm
            </Button>
            <Button variant="outlined" color="primary" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

