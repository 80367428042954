import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import { ListItem } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import GridViewIcon from '@mui/icons-material/GridView';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ArticleIcon from '@mui/icons-material/Article';
import ArchiveIcon from '@mui/icons-material/Archive';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LogoutIcon from '@mui/icons-material/Logout';
import Logo from '../../images/VERTICALLOGO.svg'

export const titleList = (
    <React.Fragment>
        <ListItem sx={{ paddingLeft:'1%'}}>
            <ListItemIcon >
                <img src={Logo} alt='logo' width="50%" />
            </ListItemIcon>
        </ListItem>
    </React.Fragment>
)

export const mainListItems = (
    <React.Fragment>
        <ListItemButton href='dashboard'>
            <ListItemIcon>
                <GridViewIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
        </ListItemButton>
        <ListItemButton href='accounts'>
            <ListItemIcon>
                <PeopleAltIcon />
            </ListItemIcon>
            <ListItemText primary="Accounts" />
        </ListItemButton>
        <ListItemButton href='requesters'>
            <ListItemIcon>
                <ArticleIcon />
            </ListItemIcon>
            <ListItemText primary="Requesters" />
        </ListItemButton >
        <ListItemButton href='archive'>
            <ListItemIcon>
                <ArchiveIcon />
            </ListItemIcon>
            <ListItemText primary="Archive" />
        </ListItemButton>
    </React.Fragment>
);

export const secondaryListItems = (
    <React.Fragment>
      <ListSubheader component="div" inset>
        Admin Account
      </ListSubheader>
      <ListItemButton href='/logout'>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
    </React.Fragment>
  );