import { createContext, useState, useMemo } from "react";
import { createTheme } from '@mui/material';

export const token = (mode) => ({
    ...(mode === 'light'
    ?{
        grey: {
            100: "#fdfdfd",
            200: "#fbfbfb",
            300: "#f9f9f9",
            400: "#f7f7f7",
            500: "#f5f5f5",
            600: "#c4c4c4",
            700: "#939393",
            800: "#626262",
            900: "#313131"
        },
        primary: {
            100: "#687EFF",
            200: "#b0b0b0",
            300: "#898989",
            400: "#616161",
            500: "#25203A",
            600: "#2e2e2e",
            700: "#232323",
            800: "#171717",
            900: "#0c0c0c"
        },
        greenAccent: {
            100: "#e3feda",
            200: "#c8fdb5",
            300: "#acfc90",
            400: "#91fb6b",
            500: "#75fa46",
            600: "#5ec838",
            700: "#46962a",
            800: "#2f641c",
            900: "#17320e"
        },
        redAccent: {
            100: "#fdd7d7",
            200: "#fbb0b0",
            300: "#f98888",
            400: "#f76161",
            500: "#f53939",
            600: "#c42e2e",
            700: "#932222",
            800: "#621717",
            900: "#310b0b"
        },
        blueAccent: {
            100: "#dce8f7",
            200: "#bad0ef",
            300: "#97b9e7",
            400: "#75a1df",
            500: "#528ad7",
            600: "#426eac",
            700: "#315381",
            800: "#213756",
            900: "#101c2b"
        },
    }:
    {
        grey: {
            100: "#313131",
            200: "#626262",
            300: "#939393",
            400: "#c4c4c4",
            500: "#f5f5f5",
            600: "#f7f7f7",
            700: "#f9f9f9",
            800: "#fbfbfb",
            900: "#fdfdfd",
        },
        primary: {
            100: "#0c0c0c",
            200: "#171717",
            300: "#232323",
            400: "#2e2e2e",
            500: "#3a3a3a",
            600: "#616161",
            700: "#898989",
            800: "#b0b0b0",
            900: "#d8d8d8",
        },
        greenAccent: {
            100: "#17320e",
            200: "#2f641c",
            300: "#46962a",
            400: "#5ec838",
            500: "#651fff",
            600: "#91fb6b",
            700: "#acfc90",
            800: "#c8fdb5",
            900: "#e3feda",
        },
        redAccent: {
            100: "#310b0b",
            200: "#621717",
            300: "#932222",
            400: "#c42e2e",
            500: "#f53939",
            600: "#f76161",
            700: "#f98888",
            800: "#fbb0b0",
            900: "#fdd7d7",
        },
        blueAccent: {
            100: "#101c2b",
            200: "#213756",
            300: "#315381",
            400: "#426eac",
            500: "#528ad7",
            600: "#75a1df",
            700: "#97b9e7",
            800: "#bad0ef",
            900: "#dce8f7",
        },
    }
    )
});


export const themeSetting = (mode) => {
    const colors = token(mode);

    return {
        palette:{
            ...(mode === 'theme'
            ?   {
                    primary:{
                        main: colors.primary[500],
                    },
                    secondary:{
                        main: colors.greenAccent[500],
                    },
                    neutral:{
                        dark: colors.grey[700],
                        main: colors.grey[500],
                        light: colors.grey[100],
                    },
                    background:{
                        default: colors.primary[500],
                    },
                }   
                :
                {
                    primary:{
                        main: colors.primary[100],
                    },
                    secondary:{
                        main: colors.greenAccent[500],
                    },
                    neutral:{
                        dark: colors.grey[700],
                        main: colors.grey[500],
                        light: colors.grey[100],
                    },
                    background:{
                        default: "#FBF7F3",
                    },
                }),       
        },
        typography:{
            fontFamily: ['Inter', "sans-serif"].join(","),
            fontSize: 12,
            h1:{
                fontFamily: ['Inter', "sans-serif"].join(","),
                fontSize: 40,
            },
            h2:{
                fontFamily: ['Inter', "sans-serif"].join(","),
                fontSize: 32,
            },
            h3:{
                fontFamily: ['Inter', "sans-serif"].join(","),
                fontSize: 24,
            },
            h4:{
                fontFamily: ['Inter', "sans-serif"].join(","),
                fontSize: 20,
            },
            h5:{
                fontFamily: ['Inter', "sans-serif"].join(","),
                fontSize: 16,
            },
            h6:{
                fontFamily: ['Inter', "sans-serif"].join(","),
                fontSize: 14,
            },
        }
    }
}

export const ColorModeContext = createContext({
    toggleColorMode: () =>{}
});

export const useMode = () => {
    const [mode, setMode] = useState("light");

    const colorMode = useMemo(
        () => ({
            toggleColorMode: () =>
                setMode((prev) => (prev === 'dark' ? "light" : "dark")),
        })
    )

    const theme = useMemo(() => createTheme(themeSetting(mode)), [mode])

    return [theme, colorMode];
}