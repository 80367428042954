import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Collapse from '@mui/material/Collapse';
import { keyframes } from '@emotion/react';
import WarningIcon from '@mui/icons-material/Warning';

//firebase
import { addDoc, collection, serverTimestamp, doc, updateDoc, where, getDocs, query } from "firebase/firestore";
import { auth, db } from "../../../config/firebase";

export const EditDocModal = ({data, studentId}) =>  {
  const [open, setOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [error, setError] = useState('');
  
  const [formData, setFormData] = useState({
    lrn: '',
    documentType: '',
    Availability:'',
  });

   //generates character
   const generateRandomString = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    
    const getRandomChar = (pool) => pool[Math.floor(Math.random() * pool.length)];
  
    let randomString = '';
    for (let i = 0; i < 6; i++) {
      randomString += getRandomChar(characters);
    }
  
    return randomString;
  };

  const handleInputChange = (e) => {
    
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

  // Clear the error when the user starts typing
  if (error && formData[e.target.name] !== '') {
    setError('');
  }
  };

  const handleOpen = () => {
    setOpen(true);
    setError(''); // Reset error when opening the main modal

    setFormData((prevFormData) => ({
      ...prevFormData,
      lrn: studentId,
      documentType: data.documentType,
      Availability: data.availability,
    }));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmationOpen = () => {
    const { lrn, documentType , Availability} = formData;
  
  // Validate the fields
  if (  lrn === '' || documentType === '' || Availability === '') {
    setError('Please fill in all required fields.'); // Set error if required fields are not filled
  } else {
 
    let lrnError = '';



    // Validate LRN
    if (!/^\d{12}$/.test(lrn)) {
      lrnError = 'LRN should be a 12-digit number.';
    }

    // Set errors if any
    if ( lrnError) {
      setError(` ${lrnError}`);
    } else {
      setError('');
      
      // If data is claimed, show separate confirmation UI
      if (Availability === 'claimed') {
        setConfirmationOpen(true);
      } else {
        setConfirmationOpen(true); // Change this line with the original confirmation logic
      }
    }
  }
};

  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
    setFormData({
      lrn: '',
      documentType: '',
      Availability:'',
    });
    handleClose();
  };
  // go back to first modal
  const handleEdit = () => {
    handleConfirmationClose();
    handleOpen();
  };
  // to submit the requested form
  const handleSubmit = async () => {
    console.log(studentId);
  
    try {
      const studentCollectionRef = collection(db, 'students');
      const userQuerySnapshot = await getDocs(query(studentCollectionRef, where('id', '==', studentId)));
  
      if (userQuerySnapshot.size > 0) {
        const userDocRef = userQuerySnapshot.docs[0].ref;
        const documentsCollectionRef = collection(userDocRef, 'documents');
  
        const { documentType, Availability } = formData;
  
        const documentData = {
          updatedDateTime: serverTimestamp(),
          documentType: documentType,
          availability: Availability,
        };
  
        // Assuming data.id is the ID of the document you want to update
        const documentRef = doc(documentsCollectionRef, data.id);
  
        await updateDoc(documentRef, documentData);
  
        console.log('Document successfully updated:', documentRef);
      }
  
      // Show an alert after successful submission
      alert('Document updated successfully!');
    } catch (err) {
      console.error('Error updating document:', err);
    }
  };
  // to close both modal
  const handleCancel = () => {
    setFormData({
      lrn: '',
      documentType: '',
      Availability:'',
    });
    handleClose();
    handleConfirmationClose();
  };

  const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
`;

  return (
    <div>
      <Button variant="contained" color="success" onClick={handleOpen}>
        update
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Grow in={open}>
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400, // Adjust the width as needed
              backgroundColor: '#fff',
              padding: 20,
              borderRadius: 8,
              outline: 'none',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '-10px' }}>
              <IconButton color="black" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>

            <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Update Document</h2>
            <Collapse in={!!error} timeout={500}>
              <div style={{ marginTop: '10px', animation: `${bounce} 1s ease` }}>
                <Alert severity="error">
                  <AlertTitle>Oops!</AlertTitle>
                  {error}
                </Alert>
              </div>
            </Collapse>
         
           <TextField
              required
              label="LRN (Learner's Reference Number)"
              name="lrn"
              value={formData.lrn}
              onChange={handleInputChange}
              fullWidth
              disabled
              margin="normal"
              variant="outlined"
              helperText={
                (error && formData.lrn === '') ? 'Enter your LRN' :
                (error && !/^\d{12}$/.test(formData.lrn)) ? 'LRN should be a 12-digit number' :
                ''
              }
              error={(error && formData.lrn === '') || (error && !/^\d{12}$/.test(formData.lrn))}
              inputProps={{
                inputMode: 'numeric', // Set input mode to numeric for mobile users
                pattern: '[0-9]*',    // Allow only numeric input
              }}
            />
            <TextField
              required
              label="Document Type"
              name="documentType"
              value={formData.documentType}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              variant="outlined"
              disabled
              helperText={
                (error && formData.lrn === '') ? 'Enter your LRN' :
                (error && !/^\d{12}$/.test(formData.lrn)) ? 'LRN should be a 12-digit number' :
                ''
              }
              error={(error && formData.lrn === '') || (error && !/^\d{12}$/.test(formData.lrn))}
              inputProps={{
                inputMode: 'numeric', // Set input mode to numeric for mobile users
                pattern: '[0-9]*',    // Allow only numeric input
              }}
            />
            
            <FormControl fullWidth margin="normal" variant="outlined">
                <InputLabel htmlFor="Availability">Availability</InputLabel>
                <Select
                  required
                  value={formData.Availability}
                  onChange={(e) => handleInputChange({ target: { name: 'Availability', value: e.target.value } })}
                  inputProps={{
                    name: 'Availability',
                    id: 'Availability',
                  }}
                  label="Availability"
                  helperText={formData.Availability ? '' : 'Select the document availability'}
                  error={error && formData.Availability === ''}
                >
                  <MenuItem value="available">Available</MenuItem>
                  <MenuItem value="unavailable">Unavailable</MenuItem>
                  <MenuItem value="claimed">Claimed</MenuItem>
                </Select>
              </FormControl>
            <Button variant="contained" color="primary" onClick={handleConfirmationOpen} style={{ marginTop: 20, width:'100%' }}>
              Save
            </Button>
          </div>
        </Grow>
      </Modal>
      {formData.Availability === 'claimed' && (
        <Modal open={confirmationOpen} onClose={handleConfirmationClose}>
          {/* Separate confirmation UI for claimed data */}
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              backgroundColor: '#fff',
              padding: 20,
              borderRadius: 8,
              outline: 'none',
            }}
          >
            
            <div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 5, justifyContent:'center', }}>
              <WarningIcon style={{ color: '#F7E11C', marginRight: 10, fontSize: '2rem',  }}  />
            </div>
              <p>This document will be claimed and cannot be Change. Do you wish to continue?</p>
              <Button fullWidth variant="contained" color="primary" sx={{mb:'10px'}} onClick={() => { handleSubmit(); handleConfirmationClose(); }}>
                Confirm Update
              </Button>
              <Button fullWidth variant="outlined" color="primary" onClick={handleConfirmationClose}>
                  Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
   {formData.Availability !== 'claimed' && (
      <Modal open={confirmationOpen} onClose={handleConfirmationClose}>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            backgroundColor: '#fff',
            padding: 20,
            borderRadius: 8,
            outline: 'none',
          }}
        >
          <div style={{ marginBottom: 15, background: formData.lrn ? '#e6f7ff' : 'transparent',fontSize: '19px', borderRadius:'5px', padding:'6px' }}>
            <strong>LRN:</strong> {formData.lrn}
          </div>
          <div style={{ marginBottom: 15, background: formData.documentType ? '#e6f7ff' : 'transparent',fontSize: '19px', borderRadius:'5px', padding:'6px' }}>
            <strong>Document Type:</strong> {formData.documentType}
          </div>
          <div style={{ marginBottom: 15, background: formData.Availability ? '#e6f7ff' : 'transparent',fontSize: '19px', borderRadius:'5px', padding:'6px'}}>
            <strong>Availability:</strong> {formData.Availability}
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Button variant="contained" color="primary" onClick={() => { handleSubmit(); handleConfirmationClose(); }}  style={{ marginBottom: 10 }}>
              Confirm
            </Button>

            <Button variant="contained" color="secondary" onClick={handleEdit} style={{ marginBottom: 10 }}>
              Edit
            </Button>

            <Button variant="outlined" color="primary" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
       )}
    </div>
  );
};

