import AppRouters from "./components/app-router";
import { CacheProvider } from "./components/cache-provider";
import { ColorModeContext, useMode } from "./components/theme";
import {CssBaseline, ThemeProvider } from "@mui/material";
import ErrorBoundary from "./pages/error/page";
import { PageNotFound } from "./pages/404/page";
function App() {
  const [theme, colorMode] = useMode();

  return (
    <CacheProvider>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline/>
              <ErrorBoundary>
                <AppRouters/>
              </ErrorBoundary>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </CacheProvider>
  );
}

export default App;