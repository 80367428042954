import * as React from 'react';
import {
  Box,
  Container,
  Paper,
  Stack,
  Checkbox,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  InputAdornment,
  IconButton,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';
import { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { AdminAccountsTable } from './components/components';
import TableModal from './components/addmodal';
import UpdateModal from './components/updatemodal';
//firebase
import { db } from '../../../../config/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { ReplayOutlined } from '@mui/icons-material';

export const AdminAccountsModule = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [adminData, setAdminData] = useState([]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  //gets data from firestore
  React.useEffect(() => {
    fetchAdminData(); // Call the function when the component mounts
  }, []);

  const fetchAdminData = async () => {
    const accountsCollection = collection(db, 'accounts');
    const cache = localStorage.getItem('cache');
    const parsedCache = JSON.parse(cache);
    const userId = parsedCache.uid.toString();

    // Create a query to get all documents where userId matches
    const q = query(accountsCollection);

    try {
      const querySnapshot = await getDocs(q);

      // Extract data from query snapshot
      const adminData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      console.log('User Data:', adminData);
      setAdminData(adminData);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const handleReloadData = () => {
    fetchAdminData();
  }
  // fetchAdminData();

  return (
    <Box sx={{ bgcolor:'#FFFFF0'}}>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: '65vh',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <Container maxWidth="100%" sx={{ mt:1,   }}>
          <Stack direction="column" spacing={1}>
          <Paper elevation={3} sx={{ overflow: 'auto', }}>
               <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                padding={2}
                sx={{ position: 'sticky', top: 0, backgroundColor: '#fff', zIndex: 1000 }}
              >
                <Box>
                  <h2>Admin Accounts</h2>
                </Box> 
                <Stack direction="row" spacing={1} alignItems="center" sx={{ paddingTop: 1 }}>
 
                    <TableModal />
                    <Button variant='contained' startIcon={<ReplayOutlined />} onClick={handleReloadData}>Reload</Button>
         
                    {/* <UpdateModal/> */}
                </Stack>
              </Stack>
                <AdminAccountsTable data={adminData}/>
            </Paper>
          </Stack>
        </Container>
      </Box>

      </Box>
    );
  };
  

  