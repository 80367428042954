import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Button, CircularProgress, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { db } from '../../../config/firebase';
import { collection, doc, getDocs, query, where } from 'firebase/firestore';
import { format } from 'date-fns'; // Add this line
import { KeyboardArrowRight, KeyboardArrowDown } from '@mui/icons-material';
import { AddDocModal } from './add-doc-modal';
import { EditDocModal } from './edit-doc-modal';
import Tooltip from '@mui/material/Tooltip';
import BlockIcon from '@mui/icons-material/Block';

export const ArchiveTable = ({ reload , searchQuery }) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [data, setData] = useState([]); 
    const [loading, setLoading] = React.useState(data.length === 0);
    const [expandedRows, setExpandedRows] = React.useState({});
    const [selectedRow, setSelectedRow] = useState(null);
    const [documentData, setDocumentData] = useState([]);
    const [selectedId, setSelectedId] = useState('');
  
    const getStudentData = async () => {
      try {
        setLoading(true);
        const studentCollectionRef = collection(db, 'students');
        const querySnapshot = await getDocs(studentCollectionRef);
        const resultData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setData(resultData);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
  
    useEffect(() => {
      getStudentData();
    }, [reload]); // Trigger the effect on reload change

    const columns = [
      { id: 'id', label: 'LRN(Learners Reference Number)', minWidth: 100 },
      { id: 'name', label: 'Name', minWidth: 100, render: (rowData) => `${rowData.firstname} ${rowData.lastname}` },
      { id: 'dateAdded', label: 'Date Registered', minWidth: 100 },
      {
        id: 'addDocument',
        label: 'Action',
        minWidth: 100,
        render: (rowData) => (
          <Button  onClick={() => handleAddDocument(rowData)}>
          <AddDocModal data={rowData}/>
          </Button>
        ),
      },
      {
        id: 'collapseButton',
        minWidth: 10,
        render: (rowData) => (
          <IconButton
            aria-label='expand'
            onClick={() => handleCollapseClick(rowData.id)}
          >
            {expandedRows[rowData.id] ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
          </IconButton>
        ),
      },
    ];
  
    const documentColumns = [
      { id: 'documentType', label: 'Document Type', minWidth: 100 },
      { id: 'updatedDateTime', label: 'Updated Date Time', minWidth: 100 },
      { id: 'availability', label: 'Availability', minWidth: 100 },
      {
        id: 'editDocument',
        label: 'Action',
        minWidth: 100,
        render: (document) => (
          document.availability === 'claimed' ? (
            <Tooltip title="This document is claimed and cannot be changed">
              <span>
                <IconButton disabled>
                  <BlockIcon />
                </IconButton>
              </span>
            </Tooltip>
          ) : (
            <Button onClick={() => handleEditDocument(document)}>
              <EditDocModal data={document} studentId={selectedId} />
            </Button>
          )
        ),
      },
    ];
  
    const handleAddDocument = async (rowData) => {
      console.log(`Add document for student ID: ${rowData.id}`);
      try {
        
      } catch (err) {
        console.error(err);
      }
    };
  
    const handleEditDocument = async (document) => {
      if (document.availability === 'claimed') {
        console.log(`Data is claimed. Cannot edit.`);
        // You may show a message or take appropriate action when the data is claimed.
        return;
      }
    
      console.log(`Edit document ${document.id} for student ID: ${selectedId}`);
      // Add the logic to handle editing when the data is not claimed.
    };
  
    const handleCollapseClick = async (rowId) => {
        if (!expandedRows[rowId]) {
          const studentCollectionRef = collection(db, 'students');
          const userQuerySnapshot = await getDocs(query(studentCollectionRef, where('id', '==', rowId)));
          if (userQuerySnapshot.size > 0) {
            const userDocRef = userQuerySnapshot.docs[0].ref;
            const requestCollectionRef = collection(userDocRef, 'documents');
      
            const documentsSnapshot = await getDocs(requestCollectionRef);
            const resultData = documentsSnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
      
            setSelectedId(rowId);
            setDocumentData(resultData);
          }
        } else {
          setSelectedId('0');
        }
      
        setSelectedRow(rowId);
        setExpandedRows((prev) => ({
          ...prev,
          [rowId]: !prev[rowId],
        }));
      };
      
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

      // Filter data based on the search query
      const filteredData = data.filter((row) => {
        const fullName = `${row.firstname} ${row.lastname}`.toLowerCase();
        const lrn = row.id.toLowerCase(); // Assuming LRN is stored in the 'id' field
        const queryLower = searchQuery.toLowerCase();
      
        return fullName.includes(queryLower) || lrn.includes(queryLower);
        // You can customize this logic based on your specific requirements
      });
  
    return (
        <Paper sx={{ width: '100%', overflow: 'hidden', mb: 1 }}>
   
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }} sx={{ backgroundColor: '#D7F4FF' }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={columns.length} align="center">
                      <CircularProgress size={50} sx={{ color: '#3A78CD' }} />
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <React.Fragment key={row.id}>
                      <TableRow hover role="checkbox" tabIndex={-1}>
                        {columns.map((column) => (
                          <TableCell key={column.id} align={column.align}  sx={{cursor: 'pointer', backgroundColor: selectedRow === row.id ? '#F0F0F0' : '#fff', }}  onClick={() => handleCollapseClick(row.id)}>
                            {column.id === 'id' ? row.id : column.id === 'name'
                              ? `${row.firstname} ${row.lastname}`
                              : column.id === 'addDocument'
                              ? column.render(row)
                              : column.id === 'dateAdded'
                              ? (row.dateAdded && row.dateAdded.toDate) ? format(row.dateAdded.toDate(), 'MMM dd, yyyy : hh:mm a') : 'N/A'
                              : column.id === 'collapseButton'
                              ? (
                                <IconButton
                                  aria-label='expand'
                                  onClick={() => handleCollapseClick(row.id)}
                                >
                                  {expandedRows[row.id] ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                                </IconButton>
                              )
                              : null}
                          </TableCell>
                        ))}
                      </TableRow>
                      {expandedRows[row.id] && selectedRow === row.id && (
                        <TableRow>
                          <TableCell colSpan={columns.length}>
                            <Table stickyHeader aria-label="sticky table">
                              <TableHead>
                                <TableRow>
                                  {documentColumns.map((column) => (
                                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }} sx={{ backgroundColor: '#E6FEFF' }}>
                                      {column.label}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {documentData.map((document) => (
                                  <TableRow key={document.id}>
                                    {documentColumns.map((column) => (
                                      <TableCell key={column.id} align={column.align} sx={{bgcolor:'#F2FFFB'}}>
                                        {column.id === 'documentType' ? document.documentType :
                                         column.id === 'availability' ? document.availability :
                                          column.id === 'editDocument' ? column.render(document) :
                                            column.id === 'updatedDateTime' ? (document.updatedDateTime && document.updatedDateTime.toDate) ? format(document.updatedDateTime.toDate(), 'yyyy-MM-dd HH:mm:ss') : 'N/A' :
                                              null}
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 100]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      );
};
