import { Box, Button, Typography } from '@mui/material';
import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      isRuntimeError: false,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error to a service like Sentry or your server here
    console.error('Error caught by error boundary:', error, errorInfo);
  }

  componentDidMount() {
    // Simulate a runtime error (you can replace this with your actual runtime error detection logic)
    setTimeout(() => {
      this.setState({ isRuntimeError: true });
    }, 5000);
  }

  render() {
    const { hasError, isRuntimeError } = this.state;

    if (hasError) {
      if (isRuntimeError) {
        // Render custom UI for runtime errors
        return (
                
          <div>
            <h2>Runtime Error: Something unexpected happened.</h2>
            {/* Add any additional content or styling you want */}
          </div>
        );
      } else {
        // Render default UI for other errors
        return (
            <Box
            sx={{
                backgroundColor: '#7caef2',
                display: 'flex',         // Add display: flex
                flexDirection: 'column', // Set flex direction to column
                justifyContent: 'center', // Center content vertically
                alignItems: 'center',
                width: '100%',
                height: '100%',
                textAlign: 'center',
            }}
            >
            <Typography sx={{ fontSize: 100, textDecoration: 'underline', fontWeight: 400, marginBottom: 0 }}>
                Oh no.
            </Typography>
            <Typography sx={{ fontSize: 20, fontWeight: 400,   marginBottom: 2}}>
                Something went wrong. Please try again later.
            </Typography>
    
            <Button variant='contained' href='/home' sx={{width: '20%', fontSize: '12px'}}>Home</Button>
            </Box>
           );
      }
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
