import { Typography } from '@mui/material';
import React, {useState, useEffect} from 'react';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

//firebase
import { auth, db } from '../../../config/firebase';
import { collection, getDocs, query, where, orderBy } from 'firebase/firestore';
import { getISOWeek, startOfMonth, endOfMonth } from 'date-fns';

export const RequestLineChart = () => {
  
  const getCurrentDate = () => {
    const currentDate = new Date();
    return currentDate.toLocaleDateString('en-US', {
      month: 'long',
      year: 'numeric'
    });
  };

  const [currentDate, setCurrentDate] = useState(getCurrentDate);

  const data = [
    {name: "Week 1", value: 2},
    {name: "Week 2", value: 0},
    {name: "Week 3", value: 2},
    {name: "Week 4", value: 2},
  ];

  const [requestCountByWeek, setRequestCountByWeek] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      // Get the first and last day of the current month
      const firstDayOfMonth = startOfMonth(new Date());
      const lastDayOfMonth = endOfMonth(new Date());

      const numberOfRequestRef = collection(db, 'requests');
      const querySnapshot = await getDocs(
        query(
          numberOfRequestRef,
          where('requestDateTime', '>=', firstDayOfMonth),
          where('requestDateTime', '<=', lastDayOfMonth),
          orderBy('requestDateTime')
        )
      );

      const requestCounts = {};

      querySnapshot.forEach((doc) => {
        const requestDateTime = doc.data().requestDateTime.toDate();
        const weekNumber = getISOWeek(requestDateTime);
        const weekKey = `Week ${weekNumber}`;

        if (!requestCounts[weekKey]) {
          requestCounts[weekKey] = 1;
        } else {
          requestCounts[weekKey]++;
        }
      });

      const data = Object.keys(requestCounts).map((week) => ({
        name: week,
        value: requestCounts[week],
      }));

      setRequestCountByWeek(data);
    };

    fetchData();
  }, []);

  return (
    <Card className="shadow mb-4" style={{ backgroundColor: '#fff', color: '#fff' }}>
      <CardHeader className="border-0">
        <Row className="align-items-center">
          <Col xs="8">
            {/* Add any header content if needed */}
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <div>
          <Typography style={{ color: '#fff', textAlign: 'left', fontSize: '12px', marginBottom: '10px', color:'rgb(206, 212, 218) ' }} >Overview</Typography>
          <Typography style={{ color: '#fff', textAlign: 'left', fontSize: '16px', marginBottom: '10px',color: ' #000', fontWeight:'bold' }}>
            Number of Request
          </Typography>
        </div>
        <ResponsiveContainer width="100%" height={334}>
          <LineChart data={requestCountByWeek}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" tick={{ fill: '#888888' }} />
            <YAxis />
            <Tooltip />
            <Line type="monotone" dataKey="value" stroke="#5e72e4" />
          </LineChart>
        </ResponsiveContainer>

          <Typography sx={{color: '#000000', justifyContent:'center', textAlign:'center'}} variant='h3'>{currentDate.toString()}</Typography>
      </CardBody>
    </Card>
  );
};