import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Protected } from "./protected";
import { Root } from "./root";
import { SignupPage } from "../pages/register/page";
import { LoginPage } from "../pages/login/page";
import { Logout } from "./logout";
import { PageNotFound } from "../pages/404/page";
import { AdminDocuments } from "../pages/document/page";
import { AccountPage } from "../pages/account/page";
import { AccountRegisteredPage } from "../pages/verify/account-registered/page";
import { Dashboard } from "@mui/icons-material";
import { DashboardPage } from "../pages/dashboard/page";
import { ForgotPassword } from "../pages/forgotpassword/page";
import { AdminArchive } from "../pages/archive/main-page";



const AppRouters = () => {
    const router = createBrowserRouter([
      {
        path: '/',
        element: <Root />,
        children: [
          {
            path: 'signup',
            element: <SignupPage />,
          },
          {
            path: 'login',
            element: <LoginPage />,
          },
          ,
          {
            path: 'forgot',
            element: <ForgotPassword/>,
          },
          {
            path: 'account-verification-success',
            element: <AccountRegisteredPage/>
          },
          {
            path: '/',
            element: <Protected/>,
            children: [ 
              {
                path: '/dashboard',
                element: <DashboardPage/>
              },
              {
                path: '/requesters',
                element: <AdminDocuments/> ,
              },
              {
                path: '/accounts',
                element: <AccountPage/> ,
              },
              {
                path: '/archive',
                element: <AdminArchive/> ,
              },
              {
                path: '/logout',
                element: <Logout/>,
              },
            ],
          },
        ],
      },

      // 404 if page does not exist
      {
        path: '*',
        element: <PageNotFound/>
      }
    ]);
  
    return <RouterProvider router={router} />;
  };
  
  export default AppRouters;
  