import React, { createContext, useContext, useEffect, useState } from 'react';

const CacheContext = createContext();

export const CacheProvider = ({ children }) => {
  const [cache, setCache] = useState({});
    
  useEffect(() => {
    const storedCache = localStorage.getItem('cache');
    if (storedCache) {
      setCache(JSON.parse(storedCache));
    }
    console.log(storedCache)
  }, []);

  const addToCache = (data, shouldSetLocalStorage = false) => {
    setCache((prevCache) => {
      const newCache = { ...prevCache, ...data };

      if (shouldSetLocalStorage) {
        localStorage.setItem('cache', JSON.stringify(newCache));
      }

      return newCache;
    });
  };

  const getFromCache = (key) => {
    return cache[key];
  };

  const removeFromCache = (key) => {
    setCache((prevCache) => {
      const newCache = { ...prevCache };
      delete newCache[key];
  
      localStorage.setItem('cache', JSON.stringify(newCache));
  
      // Return the updated cache
      return newCache;
    });
  };

  return (
    <CacheContext.Provider value={{ addToCache, getFromCache, removeFromCache }}>
      {children}
    </CacheContext.Provider>
  );
};

export const useCache = () => useContext(CacheContext);
