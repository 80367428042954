import React, { useRef, useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { Stack } from '@mui/material';
import emailjs from '@emailjs/browser';

//firebase
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { auth, db } from '../../../../../config/firebase';



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function TableModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [role, setRole] = React.useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [contact, setContact] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [password, setPassword] = useState('');
  const [validationError, setValidationError] = useState({
    firstname: false,
    lastname: false,
    role: false,
    phone: false,
    address: false,
    email: false,
    password: false,
});
const [verificationLink, setVerificationLink] = useState('');
const emailBodyRef = useRef({
  user_name: '',
  user_email: '',
  user_password: '',
  user_verify_link: '',
});
const serializeEmailBody = () => {
  return {
    to_email: email,
    user_role: role,
    user_name: firstname + " " +  lastname,
    user_password: password,
  };
};

//generates randompassword
const generateRandomPassword = () => {
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let password = '';
  for (let i = 0; i < 8; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    password += charset.charAt(randomIndex);
  }
  return password;
};




  const handleValidation = () => {
    const updatedValidationError = {
        firstname: !firstname.trim(),
        lastname: !lastname.trim(),
        role: !role.trim(),
        phone: !contact.trim(),
        address: !address.trim(),
        email: !email.trim(),
        password: !password.trim(),
    };

    setValidationError(updatedValidationError);
    // Return true if there are no validation errors
    return !Object.values(updatedValidationError).some((error) => error);
  };

  const storeUserInformation = async (user) =>{
    const key = user.uid;
    const accountsCollectionRef = collection(db, 'accounts');

    try{
      const accountInformation = await addDoc(accountsCollectionRef, {
          uId: key,
          firstname: firstname,
          lastname: lastname,
          email: email,
          contactNumber: contact,
          address: address,
          role: role,
          accountStatus: 'active',
          accountRegisteredDateTime: serverTimestamp(),
      });

        console.log("saved userdata");
      }catch(err){
          console.error(err);
      }
  }

  //sends email to user
  const sendEmailToUser = () => {
    const serializedEmailBody = serializeEmailBody();
    console.log(serializedEmailBody)
    emailjs.send(
      process.env.REACT_APP_EMAIL_JS_SERVICE_ID,
      'template_j8iuar1',
      serializedEmailBody,
      process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY
    )
    .then((result) => {
      console.log(result.text);
    })
    .catch((error) => {
      console.log(error.text);
    });
  }

  //generates verification link
  const generateEmailVerificationLink = async (user) => {
    const actionCodeSettings = {
      url: process.env.REACT_APP_WEB_URL + '/account-verification/code=?' + user, // Replace with your actual verification URL
      handleCodeInApp: true,
    };
  
    const link = await auth.generateEmailVerificationLink(email, actionCodeSettings);
    return link;
  };


  const handleRegisterAccount = async () => {
    setPassword(generateRandomPassword());
    if (!handleValidation()) {
      // Stop registration if there are validation errors
      return;
    }
  
    // Continue with registration logic
    // For example, you can call an API to register the account here
    try {
        const adminCredential = await createUserWithEmailAndPassword(auth, email, password);

        const admin = adminCredential.user;

        //saves data to firestore
        storeUserInformation(admin);
        console.log(password);

        // //sends email verification
        // const verificationLink = await generateEmailVerificationLink(admin);

        //sets verification lin
        setVerificationLink(verificationLink);
        
        //sends email verification
        await sendEmailVerification(admin);

        //send email notif on reqistration
        sendEmailToUser();


        //emails user
        sendEmailToUser();
        alert("admin registered successfully");
    } catch (err) {
      console.error(err)
    } finally{
      // Reset the form fields
      setFirstname('');
      setLastname('');
      setAddress('');
      setContact('');
      setRole('');
      setEmail('');
      setPassword('');
      // Close the modal
      handleClose();
    }
  };
  
  return (
    <div>
      <Button variant='outlined'   sx={{ '&:hover': { backgroundColor: '#135592', color:'white',  }}} onClick={handleOpen}>Add</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{ position: 'absolute', top: 0, right: 15 }}
          >
            <CloseIcon />
          </IconButton>
         
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add Account
          </Typography>
            <Stack direction={'row'} gap={2}>
              <TextField
              label="First Name"
              fullWidth
              margin="normal"
              onChange={(e) => setFirstname(e.target.value)}
              error={validationError.firstname}
              helperText={validationError.firstname ? 'Firstname is required' : ''}
              onFocus={() => setValidationError({ ...validationError, firstname: false })}
            />
            <TextField
              label="Last Name"
              fullWidth
              margin="normal"
              onChange={(e) => setLastname(e.target.value)}
              error={validationError.lastname}
              helperText={validationError.lastname ? 'Lastname is required' : ''}
              onFocus={() => setValidationError({ ...validationError, lastname: false })}
            />
            </Stack>
          <TextField
            label="Address"
            fullWidth
            margin="normal"
            onChange={(e) => setAddress(e.target.value)}
            error={validationError.address}
              helperText={validationError.address ? 'Address is required' : ''}
              onFocus={() => setValidationError({ ...validationError, address: false })}
          />
          <TextField
            label="Contact Number"
            fullWidth
            margin="normal"
            onChange={(e) => setContact(e.target.value)}
            error={validationError.phone}
            helperText={validationError.phone ? 'Contact Number is required' : ''}
            onFocus={() => setValidationError({ ...validationError, phone: false })}
          />
          
          <TextField
          id="outlined-select-currency"
          select
          label="Role *"
          fullWidth
          margin='normal'
          value={role} // Add this line to bind the value
          onChange={(e) => setRole(e.target.value)}
          error={validationError.role}
          helperText={validationError.role ? 'Role is required' : ''}
          onFocus={() => setValidationError({ ...validationError, role: false })}
        >
          <MenuItem key="Admin" value="Admin">Admin</MenuItem>
          <MenuItem key="Superadmin" value="Superadmin">Super Admin</MenuItem> {/* Fix the value here */}
        </TextField>


          <TextField
            label="Email Address"
            fullWidth
            margin="normal"
            onChange={(e) => setEmail(e.target.value)}
            error={validationError.email}
            helperText={validationError.email ? 'Email Address is required' : ''}
            onFocus={() => setValidationError({ ...validationError, email: false })}
          />
          <Button sx={{width:'100%'}} variant="contained" color="primary" onClick={handleRegisterAccount}>
            Create Account
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
