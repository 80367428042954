import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Button, CircularProgress, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { db } from '../../../config/firebase';
import { collection, doc, getDocs, query, where } from 'firebase/firestore';
import { format } from 'date-fns';
import { KeyboardArrowRight, KeyboardArrowDown } from '@mui/icons-material';
import DocumentModal from './document-modal';
import { updateDoc } from 'firebase/firestore';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';

export const DocumentsTable = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedModalRow, setSelectedModalRow] = useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [data, setData] = useState([]);
  const [loading, setLoading] = React.useState(data.length === 0);
  const [expandedRows, setExpandedRows] = React.useState({});
  const [selectedRow, setSelectedRow] = useState(null);
  const [iconColor, setIconColor] = useState({});
  const [selectedData, setSelectedData] = useState({
    email: '',
    firstname: '',
  })
  const [documentData, setDocumentData] = useState([]);
  const [selectedId, setSelectedId] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const filteredData = data.filter((item) =>
  Object.values(item).some(
    (value) =>
      typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())
  )
);

    // Styled component for TableCell with hover effect
    const HoverTableRow = styled(TableRow)(({ theme }) => ({
      backgroundColor: theme.palette.action.hover,
      cursor: 'pointer',
      '&:hover':  {
        backgroundColor: theme.palette.action.hover,
      },
    }));

  const onUpdateStatus = async (documentId, newStatus, remarks) => {
    try {
      console.log('Updating status for documentId:', documentId);
      console.log('Selected modal row:', selectedModalRow);
      const requestDocRef = doc(db, 'users', selectedModalRow.id, 'requests', documentId);
      console.log('Request document reference:', requestDocRef);
      await updateDoc(requestDocRef, {
        requestStatus: newStatus,
        remarks: remarks,
      });
      // Refresh the data after updating
      getDocumentsData();
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  useEffect(() => {
    const getDocumentsData = async () => {
      try {
        setLoading(true);
        const studentCollectionRef = collection(db, 'users');
        const querySnapshot = await getDocs(studentCollectionRef);
        const resultData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        
        setData(resultData);console.log(data)
        console.log(selectedData)
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    getDocumentsData();
  }, []);

  const handleOpenModal = (row) => {
    setSelectedModalRow(row);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  //ireload ni butang sa nawong ni james este read luboton
  const getDocumentsData = async () => {
    try {
      setLoading(true);
      const studentCollectionRef = collection(db, 'users');
      const querySnapshot = await getDocs(studentCollectionRef);
      const resultData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setData(resultData);
      console.log(resultData)
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDocumentsData();
  }, []);

  const handleAddDocument = async (rowData) => {
    console.log(`Add document for student ID: ${rowData.id}`);
    // Add your implementation for adding a document
  };

  const handleEditDocument = async (document) => {
    console.log(`Edit document ${document.id} for student ID: ${selectedId}`);
    // Add your implementation for editing a document
  };

  const handleCollapseClick = async (rowId, column) => {
    if (rowId !== 'header') {
      // Handle row click as before
      if (!expandedRows[rowId]) {
        const userDocRef = doc(db, 'users', rowId);
        const requestCollectionRef = collection(userDocRef, 'requests');
  
        const documentsSnapshot = await getDocs(requestCollectionRef);
        const resultData = documentsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
  
        setSelectedId(rowId);
        setDocumentData(resultData);
        setSelectedData({
          email: data.find(item => item.id === rowId)?.email.toString(),
          firstname: data.find(item => item.id === rowId)?.firstname.toString(),
        });
  
        console.log(documentData);
        console.log(selectedData);
      }
  
      setSelectedRow(rowId);
      setExpandedRows((prev) => ({
        ...prev,
        [rowId]: !prev[rowId],
      }));
    }
  };
  
  
  //icon right and down on t

  const renderColumnValue = (column, row, index) => {
    switch (column.id) {
      case 'name':
        return `${row.firstname} ${row.lastname}`;
      case 'contact':
      case 'email':
      case 'address':
        return row[column.id];
      case 'collapseButton':
        return (
          <IconButton
            aria-label='expand'
            onClick={() => handleCollapseClick(row.id, row)}
          >
            {expandedRows[row.id] ? <KeyboardArrowDown sx={{color:'green'}}/> : <KeyboardArrowRight sx={{color:'#5F9FFF'}}/>}
          </IconButton>
        );
      default:
        return null;
    }
  };

  const columns = [
    { id: 'name', label: 'Name', minWidth: 100, render: (rowData) => renderColumnValue(columns[1], rowData) },
    // { id: 'contactNumber', label: 'Contact Number', minWidth: 100, render: (rowData) => renderColumnValue(columns[2], rowData) },
    { id: 'email', label: 'Email Address', minWidth: 100, render: (rowData) => renderColumnValue(columns[5], rowData) },
    { id: 'address', label: 'Address', minWidth: 100, render: (rowData) => renderColumnValue(columns[4], rowData) },
    { id: 'collapseButton', minWidth: 10, render: (rowData) => renderColumnValue(columns[5], rowData) },
  ];

  const documentColumns = [
    { id: 'lrn', label: 'LRN', minWidth: 100 },
    { id: 'firstname', label: 'Name', minWidth: 100 },
    { id: 'formType', label: 'Requested Type', minWidth: 100 },
    { id: 'requestDateTime', label: 'Requested Date Time', minWidth: 100, render: (document) => format(document.requestDateTime.toDate(), 'MMM dd, yyyy : hh:mm a') },
    { id: 'requestCode', label: 'Request Code', minWidth: 100 },
    { id: 'requestStatus', label: 'Request Status', minWidth: 100 },
    { id: 'remarks', label: 'Remarks', minWidth: 100 },
    {
      id: 'editDocument',
      label: 'Action',
      minWidth: 100,
      render: (document) => (
        // <Button onClick={() => handleOpenModal(document)} variant='contained' sx={{background:'blue'}}>Read</Button>,
        <Button onClick={() => handleOpenModal(document)} variant='contained' sx={{background:'green'}}>Edit</Button>
      ),
    },
  ];
  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', mb: 1 }}>
      <TableContainer sx={{ maxHeight: 460 }}>
        <Table stickyHeader aria-label="sticky table">
        <TableHead>
                <Typography variant='h1' sx={{paddingBottom:'10px', pt:'10px', pl:'10px'}}>Requesters</Typography>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }} sx={{ backgroundColor: '#D7F4FF' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  <CircularProgress size={50} sx={{ color: '#659CE8' }} />
                </TableCell>
              </TableRow>
            ) : (
              filteredData  
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                <React.Fragment key={row.id}>
                  <HoverTableRow hover role="checkbox" tabIndex={-1}>
                    {columns.map((column) => (
                      <TableCell key={column.id} align={column.align} sx={{   backgroundColor: selectedRow === row.id ? '#F0F0F0' : '#fff', }}  onClick={() => handleCollapseClick(row.id)}>
                        {renderColumnValue(column, row, index)}
                      </TableCell>
                    ))}
                  </HoverTableRow>
                  {expandedRows[row.id] && selectedRow === row.id && (
                    <TableRow>
                      <TableCell colSpan={columns.length}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              {documentColumns.map((column) => (
                                <TableCell key={column.id} align={column.align} style={{ maxWidth: column.maxWidth }} sx={{ backgroundColor: '#E6FEFF' , borderRadius:'4px' }} >
                                  {column.label}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {documentData.map((document) => (
                              <TableRow key={document.id} sx={{bgcolor:'#F2FFFB'}}>
                                {documentColumns.map((column) => (
                                  <TableCell key={column.id} align={column.align}>
                                    {column.render ? column.render(document) : document[column.id]}
                                  
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableCell>
                    </TableRow>
                    
                  )}
                </React.Fragment>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
                                  
      <DocumentModal
        open={isModalOpen}
        onClose={handleCloseModal}
        selectedRow={selectedModalRow}
        selectedData={selectedData}
        onUpdateStatus={onUpdateStatus}
      />

      
      <TablePagination
        rowsPerPageOptions={[5, 10, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
